<template>
  <div class="finance-data">
    <h5>库存管理</h5>

    <div class="mt-3">
      <div class="summary row">
        <div class="col-sm-3">
          <b-card>
          <b-row no-gutters>
            <b-col md="6">
              <b-card-img src="http://fc.iaplt.net/gzs.jpg" alt="Image" class="rounded-0"></b-card-img>
            </b-col>
            <b-col md="6">
              <div class="ml-2">
                <strong>庚子鼠</strong>
                <div>
                  剩余<br/><span style="font-size: 1.2rem;font-weight: bold">2000</span><span>张</span>
                </div>
                <div>8000/10000</div>
              </div>
            </b-col>
          </b-row>
        </b-card>
        </div>
        <div class="col-sm-3">
          <b-card>
            <b-row no-gutters>
              <b-col md="6">
                <b-card-img src="http://fc.iaplt.net/cymm.jpg" alt="Image" class="rounded-0"></b-card-img>
              </b-col>
              <b-col md="6">
                <div class="ml-2">
                  <strong>橙意满满</strong>
                  <div>
                    剩余<br/><span style="font-size: 1.2rem;font-weight: bold">2000</span><span>张</span>
                  </div>
                  <div>8000/10000</div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </div>

      </div>


      <div class="filter  mt-3">
        <a href="" class="btn btn-primary btn-sm float-right">补充库存</a>
        <h5>库存记录</h5>
        <form action="" class="form-inline" @submit="onSubmit">
          <div class="form-row align-items-center">
            <div class="col-auto">
              <input type="date" class="form-control form-control-sm" v-model="keywords.start">
            </div>
            <div class="col-auto">
              到
            </div>
            <div class="col-auto">
              <input type="date" class="form-control form-control-sm" v-model="keywords.end">
            </div>
            <div class="col-auto">
              <select name="" id="" class="form-control form-control-sm"  v-model="keywords.type">
                <option value="-1" disabled selected>请选择类型</option>
                <option value="1">兑换彩票</option>
                <option value="2">兑奖支付</option>
              </select>
            </div>
            <div class="col-auto">
              <select name="" id="" class="form-control form-control-sm"  v-model="keywords.channel">
                <option value="-1" disabled selected>请选择渠道</option>
                <option value="1">中国移动</option>
                <option value="2">中国联通</option>
                <option value="3">中国电信</option>
              </select>
            </div>
            <div class="col-auto">
              <input type="text" class="form-control form-control-sm" v-model="keywords.orderSn" placeholder="订单号">
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary btn-sm">查询</button>
            </div>
          </div>
        </form>
      </div>

      <div class="list mt-3">
        <Loading :loading="loading"></Loading>
        <BTable striped hover :items="list" head-variant="light" :fields="fields" v-if="!loading"></BTable>
      </div>
    </div>
  </div>
</template>
<script>
import Finance from "@/model/Finance"
import Loading from "@/components/Loading"
import { BTable } from 'bootstrap-vue'
// import Card from "@/components/Card"

export default {
  components:{
    BTable, Loading
  },
  data() {
    return {
      fields:[
        {key:'created_at', label:'交易时间'},
        {key:'orderSn',label:'订单号码'},
        {key:'gameTitle', label: '批次名称'},
        {key:'gameCode', label: '批次编码'},
        {key: 'quantity', label:'数量（张）'},
        {key:'amount', label:'金额'}
      ],
      list: [],
      keywords: {
        start: '',
        end: '',
        type: -1,
        channel: -1,
        orderSn: ''
      },
      loading: false
    }
  },
  computed:{
    countIncome() {
      let r = 0;
      for(let i in this.list) {
        if(this.list[i].amount > 0) {
          r += this.list[i].amount;
        }
      }
      return r;
    },
    countPay() {
      let r = 0;
      for(let i in this.list) {
        if(this.list[i].amount < 0) {
          r += this.list[i].amount;
        }
      }
      return -r;
    },
    countRatio() {
      return this.countPay / this.countIncome * 100;
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      Finance.stock(this.keywords).then(response => {
        if(response.data.code === 0) {
          this.list = response.data.data;
        }
      })
    },
    onSubmit(e) {
      e.preventDefault();
      this.loading = true;
      setTimeout(() => {this.loading = false}, 500)
      this.init();
    }
  }
}
</script>