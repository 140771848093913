import api from "@/request/api";

export default {
    list(params = {}) {
        return  api.get('finance/list', {params:params});
    },

    stock(params = {}) {
        return  api.get('stock/list', {params:params});
    },
}